import React, { FC } from 'react';
import styled from 'styled-components';
import VideoReviewsCard from './VideoReviewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import Full from 'assets/icons/full-trustpilot.svg';
import { DynamicImage } from 'components/images/DynamicImage';
import Verified from 'assets/icons/verified.svg';
import { useRouter } from 'apis/history';
import PrimaryButton from 'components/PrimaryButton';

const cardData = [
  {
    img: 'reviews/rs1.png',
    review:
      '“As a busy mom, I struggled to find time to cook healthy meals for myself. Carnimeat changed that for me. The recipes are quick and easy, and within a month, I lost 18 lbs! I feel more energetic and confident than ever. Highly recommend.”',
    name: 'Megan C., 30',
  },
  {
    img: 'reviews/rs6.png',
    review:
      '“I’m a stress-eater. I always knew it wasn’t healthy, but just couldn’t help myself. Gained a lot of weight when I had a kid, then again when I went back to work. Carnimeat helped me shed the extra pounds and finally feel good in my own body. Highly recommend.”',
    name: 'Sarah D., 37',
  },
  {
    img: 'reviews/rs2.png',
    review:
      '“After trying countless diets with no success, I was skeptical about trying this. But to my surprise, it worked! I finally broke through my weight plateau and lost ~20 lbs in just 6 weeks. The meals are so satisfying, and I always feel full.”',
    name: 'Vivienne Q., 35',
  },
  {
    img: 'reviews/rs4.png',
    review:
      '“My doctor told me I had to change something if I want to avoid diabetes, so I decided to try Carnimeat meal plan. I lost so much weight I actually had to buy all new wardrobe. It’s incredible how simple it was to lose weight. Wish I found this plan years ago.”',
    name: 'John M., 45',
  },
  {
    img: 'reviews/rs3.png',
    review:
      '“I used to have uncontrollable cravings for sweets and snacks, especially in the evenings. Carnimeat helped me gain control over my diet. The meals are delicious, filling, and keep my cravings in check. I’m down 15 lbs and counting!”',
    name: 'Pauline N, 29',
  },
  {
    img: 'reviews/rs5.png',
    review:
      '“I was constantly in the kitchen, either cooking something complicated or snacking. With carnimeat, I lost 30lbs, my constant sugar cravings are gone and meal prep takes me up to an hour (for the whole week!). This is the best life-hack ever.”',
    name: 'Donna T., 58',
  },
];

const SuccessStoriesSection: FC = () => {
  const { isTablet } = useQuery();
  const { goToLanding } = useRouter();
  return (
    <Container>
      <Title>Carnimeat success stories</Title>
      <Subtitle>Why Carnimeat? Let our customers tell you.</Subtitle>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 16 : 24}
        >
          {cardData.map((review, index) => (
            <SwiperSlide key={index}>
              <Card>
                <DynamicImage src={review.img} alt="" />
                <Inner>
                  <BottomContainer>
                    <BottomInner>
                      <Name>{review.name}</Name>
                      <Verified />
                    </BottomInner>
                    <Full />
                  </BottomContainer>
                  <CardText>{review.review}</CardText>
                </Inner>
              </Card>
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>

      <StyledButton onClick={() => goToLanding()}>
        Start your journey
      </StyledButton>
    </Container>
  );
};

export default SuccessStoriesSection;

const StyledButton = styled(PrimaryButton)`
  max-width: 21.4375rem;
  margin: 2.5rem auto 0;
  @media ${tablet} {
    margin: 2rem auto 0;
  }
`;

const Container = styled.div`
  display: flex;
  padding: 3.5rem 1rem;
  flex-direction: column;
  align-items: center;
  background: #fff;
  @media ${tablet} {
    padding: 2.5rem 1rem;
  }
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 0;
  @media ${tablet} {
    padding: 0.5rem 0 0;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 63.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 322px !important;
    margin-bottom: 28px;

    @media ${tablet} {
      margin-bottom: 16px;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #fceded;
    &-active {
      background-color: #dc4b4b;
      width: 8px;
    }
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 1px solid #f5f5f5;
  background: #f9f9f9;
  max-width: 21.375rem;
  width: 100%;
  margin: 2.5rem 0 1.5rem;
  overflow: hidden;
  @media ${tablet} {
    margin: 2rem 0;
  }
`;

const CardText = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  height: 9.625rem;
  @media ${tablet} {
  }
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0 1rem;
  width: 100%;
  @media ${tablet} {
  }
`;

const Name = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const BottomInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Inner = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem 0.75rem;
  width: 100%;
`;
