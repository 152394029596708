import React, { FC } from 'react';
import styled from 'styled-components';
import VideoReviewsCard from './VideoReviewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import Full from 'assets/icons/full-trustpilot.svg';

const cardData = [
  {
    review:
      'This made weight loss so easy! Delicious meals and no cravings—that was all I was looking for.',
    name: 'Jenny',
  },
  {
    review:
      'Dropped 10 lbs in two weeks with Carnimeat! Tasty, filling, and super effective.',
    name: 'Susan',
  },
  {
    review:
      'Finally found a meal plan that works! This plan helped me lose weight without feeling deprived.',
    name: 'William W.',
  },
  {
    review:
      'I heard that the results are fast, but I didn’t expect to look so different so quickly. My wife is shocked. Amazing!',
    name: 'Stanley',
  },
  {
    review:
      'I used to be tired from the moment I wake up to the time I go to bed. That’s gone now. I’m energetic and look super healthy.',
    name: 'Patricia',
  },
  {
    review:
      'Lost over 20lbs without even trying. Every time I step on the scale the number is lower and lower.',
    name: 'Olga',
  },
];

const SatisfiedUsersSection: FC = () => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <Title>1.6M+ satisfied users</Title>
      <Subtitle>Discover how Carnimeat changed their lives</Subtitle>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 16 : 24}
        >
          {cardData.map((review, index) => (
            <SwiperSlide key={index}>
              <Card>
                <Inner>
                  <Name>{review.name}</Name>
                  <Full />
                </Inner>
                <Review>{review.review}</Review>
              </Card>
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>
    </Container>
  );
};

export default SatisfiedUsersSection;

const Inner = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Review = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  height: 3.75rem;
`;

const Name = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const Container = styled.div`
  display: flex;
  padding: 3.5rem 1rem;
  flex-direction: column;
  align-items: center;
  background: #fceded;
  @media ${tablet} {
    padding: 2.5rem 1rem;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 0.75rem;
  border: 1px solid #f9f9f9;
  background: #fff;
  padding: 1rem;
  max-width: 20.125rem;
  width: 100%;
  overflow: hidden;
  @media ${tablet} {
  }
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 2.5rem;
  @media ${tablet} {
    padding: 0.5rem 0 2rem;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 63.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 322px !important;
    margin-bottom: 28px;

    @media ${tablet} {
      width: 280px !important;
      margin-bottom: 16px;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #fff;
    &-active {
      background-color: #dc4b4b;
      width: 8px;
    }
  }
`;
