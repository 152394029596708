import React, { FC } from 'react';
import styled from 'styled-components';
import VideoReviewsCard from './VideoReviewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import { title } from 'process';
import { DynamicImage } from 'components/images/DynamicImage';

const cardData = [
  {
    img: 'reviews/r6.png',
    title: 'So delicious',
    review:
      '"Couldn’t believe losing weight can be this quick and this delicious. I have been accused of taking ozempic more than once now. Nope, lol, just carnimeat!"',
    name: 'Abigail M.',
  },
  {
    img: 'reviews/r2.png',
    title: 'I feel SO good!',
    review:
      'I can’t believe how easy and enjoyable it is to follow Carnimeat. The meals are satisfying, and I feel healthier and more motivated than ever.',
    name: 'Sarah K.',
  },
  {
    img: 'reviews/r5.png',
    title: 'Like a personal chef',
    review:
      '"Feels so good to not have to constantly think what I’m going to cook, what I need to buy, etc. Carnimeat helped me lose weight and free up so much time for myself!”',
    name: 'Naomi C.',
  },
  {
    img: 'reviews/r3.png',
    title: 'Reduced my cravings',
    review:
      '"Before Carnimeat, I was constantly craving carbs and sugar. Now, I feel full and satisfied after every meal, and my cravings have completely disappeared."',
    name: 'Melissa P.',
  },
  {
    img: 'reviews/r4.png',
    title: 'Losing weight fast!',
    review:
      '"I can’t believe how quickly I’ve lost weight with Carnimeat. I’m down 10 lbs in just a few weeks without feeling deprived at all! Love it."',
    name: 'Victoria S.',
  },
];

const CardsSection: FC = () => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <Title>Proven results from our customers</Title>
      <Subtitle>See the impact of Carnimeat plans</Subtitle>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 16 : 24}
        >
          {cardData.map((review, index) => (
            <SwiperSlide key={index}>
              <Card>
                <DynamicImage src={review.img} alt={review.title} />
                <Inner>
                  <CardTitle>{review.title}</CardTitle>
                  <Review>{review.review}</Review>
                  <Name>{review.name}</Name>
                </Inner>
              </Card>
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>
    </Container>
  );
};

export default CardsSection;

const Inner = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  width: 100%;
`;

const Review = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  height: 6.25rem;
  padding: 0.5rem 0 0.75rem;
`;

const Name = styled.p`
  color: #000;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  padding: 3.5rem 1rem;
  flex-direction: column;
  align-items: center;
  background: #fff;
  @media ${tablet} {
    padding: 1.5rem 1rem 2.5rem;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 1px solid #f5f5f5;
  background: #f7f7f7;
  max-width: 20.125rem;
  width: 100%;
  overflow: hidden;
  @media ${tablet} {
  }
`;

const CardTitle = styled.p`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  width: 100%;
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 2.5rem;
  @media ${tablet} {
    padding: 0.5rem 0 2rem;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 63.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 322px !important;
    margin-bottom: 28px;

    @media ${tablet} {
      width: 280px !important;
      margin-bottom: 16px;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #fceded;
    &-active {
      background-color: #dc4b4b;
      width: 8px;
    }
  }
`;
