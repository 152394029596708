import React, { FC } from 'react';
import styled from 'styled-components';
import Trustpilot from 'assets/icons/trustpilot-rating.svg';
import Full from 'assets/icons/full-trustpilot.svg';
import Verified from 'assets/icons/verified.svg';
import { tablet } from 'styles/breakpoints';
import Text from 'components/Text';
import PrimaryButton from 'components/PrimaryButton';
import { useRouter } from 'apis/history';
import { DynamicImage } from 'components/images/DynamicImage';

const MainReviewSection: FC = () => {
  const { goToLanding } = useRouter();
  return (
    <Container>
      <Title>Carnimeat review highlights</Title>
      <Subtitle>Thousands of happy and healthy customers</Subtitle>
      <Trustpilot />
      <Card>
        <DynamicImage src="reviews/r1.png" alt="" />
        <Inner>
          <BottomContainer>
            <BottomInner>
              <Name>Sophia W., 36</Name>
              <Verified />
            </BottomInner>
            <Full />
          </BottomContainer>
          <CardText>
            &quot;I’m absolutely loving Carnimeat! The meal plan has completely
            changed how I feel about food. It’s delicious, and I feel stronger
            and more energetic every day!&quot;
          </CardText>
        </Inner>
      </Card>
      <StyledButton onClick={() => goToLanding()}>Get your plan</StyledButton>
    </Container>
  );
};

export default MainReviewSection;
const Inner = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem 0.75rem;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  padding: 3.5rem 1rem;
  flex-direction: column;
  align-items: center;
  background: #fceded;
  @media ${tablet} {
    padding: 1.5rem 1rem 2.5rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media ${tablet} {
    font-size: 2rem;
  }
`;

const Subtitle = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 0.75rem;
`;

const StyledButton = styled(PrimaryButton)`
  max-width: 21.375rem;
  margin: 0 auto;
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 1px solid #f9f9f9;
  background: #fff;
  max-width: 21.375rem;
  width: 100%;
  margin: 2.5rem 0 1.5rem;
  overflow: hidden;
  @media ${tablet} {
    margin: 2rem 0;
  }
`;

const CardText = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;

  @media ${tablet} {
  }
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0 1rem;
  width: 100%;
  @media ${tablet} {
  }
`;

const Name = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const BottomInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
